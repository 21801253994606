/* eslint-disable import/no-anonymous-default-export */
export default {
  unit: "px",
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}
