/* eslint-disable import/no-anonymous-default-export */
import base from "../base"

export default {
  styleOverrides: {
    root: {},
    indicator: {
      backgroundColor: base.palette.service.main,
    },
  },
}
