/* eslint-disable import/no-anonymous-default-export */
import base from "../base"

export default {
  styleOverrides: {
    root: {
      width: "100%",
      marginTop: "0.35em",
      marginBottom: "0.35em",
      borderTop: `2px solid ${base.palette.text.disabled}`,
      opacity: 0.6,
    },
  },
}
