/* eslint-disable import/no-anonymous-default-export */
import base from "../base"

export default {
  styleOverrides: {
    root: {
      color: base.palette.text.primary,
    },
  },
}
