exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-for-gamer-index-js": () => import("./../../../src/pages/blog/for-gamer/index.js" /* webpackChunkName: "component---src-pages-blog-for-gamer-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-de-js": () => import("./../../../src/pages/support.de.js" /* webpackChunkName: "component---src-pages-support-de-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

