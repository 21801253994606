/* eslint-disable import/no-anonymous-default-export */
import base from "../base"

export default {
  styleOverrides: {
    root: {
      color: base.palette.common.white,
      fontSize: "1.35rem",
      borderRadius: 60,
      paddingLeft: 15,
      paddingBottom: 5,
      height: 57,
      borderColor: base.palette.service.main,
      backgroundColor: base.palette.input.background,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: base.palette.service.main,
        borderWidth: 2,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: base.palette.common.white,
      },
      "& .Mui-focused": {
        borderColor: base.palette.service.main,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: base.palette.service.main,
      },
      "&.Mui-disabled": {
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          backgroundColor: base.palette.service.main,
          borderColor: base.palette.service.main,
        },
      },
    },
    input: {
      "&:-webkit-autofill": {
        WebkitTextFillColor: base.palette.common.white,
        WebkitBoxShadow: `0 0 0 1000px ${base.palette.primary.dark} inset`,
      },
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "white",
        opacity: 1,
      },
    },
  },
}
