/* eslint-disable import/no-anonymous-default-export */
import base from "../base"

const root = {
  textAlign: "center",
  textTransform: "uppercase",
  userSelect: "none",
}

const outlined = {
  base: {
    color: base.palette.text.primary,
    backgroundColor: base.palette.background.dark1,
    border: `2px solid ${base.palette.service.main}!important`,
    borderRadius: 8,
    "&:hover": {
      color: base.palette.common.black,
      backgroundColor: base.palette.service.main,
      border: `2px solid ${base.palette.service.main}!important`,
    },
    "&:disabled": {
      color: `${base.palette.text.disabled}`,
      border: `2px solid ${base.palette.primary.light}`,
    },
    "&.Mui-disabled": {
      backgroundColor: `${base.palette.background.button_disabled}`,
      border: `2px solid ${base.palette.text.disabled}`,
      color: `${base.palette.text.disabled}`,
    },
  },
  primary: {
    color: base.palette.text.primary,
    backgroundColor: base.palette.background.dark1,
    border: `2px solid ${base.palette.service.main}!important`,
    "&:hover": {
      color: base.palette.common.black,
      backgroundColor: base.palette.service.main,
      border: `2px solid ${base.palette.service.main}!important`,
    },
  },
  secondary: {
    color: base.palette.text.primary,
    backgroundColor: base.palette.background.dark1,
    border: `2px solid ${base.palette.service.main}!important`,
    "&:hover": {
      color: base.palette.common.black,
      backgroundColor: base.palette.service.main,
      border: `2px solid ${base.palette.service.main}!important`,
    },
  },
}

export default {
  styleOverrides: {
    root: { ...root },
    outlined: { ...outlined.base },
    outlinedPrimary: { ...outlined.primary },
    outlinedSecondary: { ...outlined.secondary },
  },
}
