/* eslint-disable import/no-anonymous-default-export */
import base from "../base"

export default {
  styleOverrides: {
    root: {
      textTransform: "inherit",
      "&.Mui-selected": {
        color: base.palette.service.main,
      },
    },
  },
}
