import { createTheme } from "@mui/material/styles"

import base from "./base"
import globals from "./global"

import accordion from "./components/accordion"
import accordionSummary from "./components/accordionSummary"
import button from "./components/button"
import divider from "./components/divider"
import inputLabel from "./components/inputLabel"
import link from "./components/link"
import outlinedInput from "./components/outlinedInput"
import paper from "./components/paper"
import select from "./components/select"
import ctypography from "./components/typography"

import tab from "./components/tab"
import tabPanel from "./components/tabPanel"
import tabs from "./components/tabs"

// https://mui.com/customization/default-theme/

const theme = createTheme({
  ...base,

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
      },
    },
    MuiAccordion: { ...accordion },
    MuiAccordionSummary: { ...accordionSummary },
    MuiButton: { ...button },
    MuiDivider: { ...divider },
    MuiInputLabel: { ...inputLabel },
    MuiLink: { ...link },
    MuiOutlinedInput: { ...outlinedInput },
    MuiPaper: { ...paper },
    MuiSelect: { ...select },
    MuiTypography: { ...ctypography },
    MuiTab: { ...tab },
    MuiTabPanel: { ...tabPanel },
    MuiTabs: { ...tabs },
  },
})

export default theme
