/* eslint-disable import/no-anonymous-default-export */
export default {
  styleOverrides: {
    root: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    underlineHover: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}
