/* eslint-disable import/no-anonymous-default-export */

export default {
  styleOverrides: {
    root: {
      padding: ".5em",
    },
    rounded: {
      boxShadow: "unset",
      backgroundColor: "#333",
      border: `1px rgba(255,255,255,.15) solid`,
    },
  },
}
