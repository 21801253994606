/* eslint-disable import/no-anonymous-default-export */
export default {
  styleOverrides: {
    root: {
      "&.Mui-expanded": {
        minHeight: 48,
      },
      "& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root": {
        color: "white",
      },
    },
    content: {
      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
  },
}
