/* eslint-disable import/no-anonymous-default-export */
export default {
  common: {
    black: "#000",
    white: "#fff",
  },
  background: {
    accordion: "rgba(77, 77, 77, .4)",
    accordion_border: "rgba(128, 128, 128, .6)",
    box_default: "rgb(77, 77, 77)",
    box_danger: "rgb(193, 39, 45)",
    button_disabled: "#333333",
    dark1: "rgba(0, 0, 0, .1)",
    dark2: "rgba(0, 0, 0, .25)",
    footer1: "rgba(66, 61, 63, .9)",
    footer2: "rgba(39, 36, 37, .9)",
    light1: "rgba(255, 255, 255, .1)",
    light2: "rgba(255,255,255,.05)",
    modal: "rgb(71, 71, 71)",
    modalbox: "rgb(91, 91, 91)",
    paper: "rgba(56, 56, 56, .95)",
  },
  input: {
    background: "rgba(51,51,51,.75)",
  },
  default: {
    main: "#686868",
  },
  primary: {
    light: "#919090",
    main: "#686868",
    dark: "#4D4D4D",
    contrastText: "#fff",
  },
  secondary: {
    main: "#39B54A",
    contrastText: "#fff",
  },
  service: {
    main: "#EAEA05",
    contrastText: "#fff",
  },
  error: {
    main: "#C1272D",
    contrastText: "#fff",
  },
  warning: {
    main: "#E28014",
  },
  selected: {
    main: "#fff",
    contrastText: "#323232",
  },
  unselected: {
    main: "#919090",
    contrastText: "#323232",
  },
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.38)",
    hint: "rgba(255, 255, 255, 0.7)",
  },
}
